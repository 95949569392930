<template>
    <div class="v-stack h-stretch gap-3">
        <button v-if="id == undefined || id == $store.state.id" class="add"
            @click="$router.push('/users/vacation/new/' + $store.state.id)">New
            Vacation</button>
        <div class="h-stack v-stretch h-space-between gap-3">
            <a class="heading-title-2">Vacations</a>
            <div class="h-stack gap-3">
                <label style="align-self: center">Created:</label>
                <input type="checkbox" v-model="created" />
                <label style="align-self: center">Signed:</label>
                <input type="checkbox" v-model="signed" />
                <label style="align-self: center">Canceled:</label>
                <input type="checkbox" v-model="canceled" />
            </div>
        </div>
        <Table :items="filteredVacations" :defaultColumn="0" :limit="15" :expandable="false"
            css="80px 80px 60px 1fr 60px" :columns="[
                {
                    name: 'From',
                    sort: 'date',
                    path: ['startDate'],
                    formatType: 'date',
                    format: 'DD.MM.YYYY',
                },
                {
                    name: 'To',
                    sort: 'date',
                    path: ['endDate'],
                    formatType: 'date',
                    format: 'DD.MM.YYYY',
                },
                {
                    name: 'Type',
                    sort: 'alphabet',
                    path: ['type'],
                },
                {
                    name: 'Description',
                    sort: 'alphabet',
                    path: ['description'],
                },
                {
                    name: 'State',
                    sort: 'alphabet',
                    path: ['state'],
                },
            ]">
        </Table>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
const moment = require("moment");

export default {
    props: ["id"],
    components: {
        Table,
    },
    data() {
        return {
            vacations: [],
            created: true,
            signed: true,
            canceled: false
        };
    },
    computed: {
        filteredVacations() {
            let vacations = this.vacations;

            if (!this.signed) {
                vacations = vacations.filter((vacation) => vacation.state != "signed");
            }

            if (!this.created) {
                vacations = vacations.filter((vacation) => vacation.state != "created");
            }

            if (!this.canceled) {
                vacations = vacations.filter((vacation) => vacation.state != "canceled");
            }

            return vacations;
        },
    },
    methods: {
        ...mapActions(["getUser", "getVacationsByUser"]),
        refresh() {
            this.getVacationsByUser(this.id || null).then((vacations) => {
                this.vacations = vacations
            }).catch((error) => {
                console.log(error)
            })
        },
        formatDate(dateString, format) {
            const date = moment(dateString);
            return date.format(format);
        },
    },
    mounted() {
        this.refresh();
    },
};
</script>